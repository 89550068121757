.deliveryRegistModal {
	.card + .card {
		margin-top: 30px;
	}

	.waybill, .returnWaybill {
		.titleArea {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 3px;
			border-bottom: 1px solid #ccc;

			h6 {
				font-size: 15px
			}

			.btnDelete {
				margin-left: 10px;
			}
		}

		.btnAddReturnWaybill {
			text-decoration: underline;
		}

		.deliveryTrackingTable{
			margin-top: 10px;
		}
	}

	.returnWaybill {
		margin-top: 20px;
	}
}