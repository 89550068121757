$formBorderColor: #d2d6da;
$formBorderRadius: 0.375rem;

// 아이콘 안나오던 문제
.MuiSelect-icon {
  display: inline-block!important;
}

// 임시 (페이지에서 적용후 삭제 예정)
.deliveryManagement {
  .MuiTable-root a {display: block}
  .MuiTable-root .gutter + * {
    margin-top: 6px;
  }
}

// 임시 (
.gnb > .MuiPaper-root > .MuiList-root > .MuiCollapse-root { 
  .MuiListItem-root {
    padding-left: 25px;
  }

  .MuiCollapse-root .MuiListItem-root {
    padding-left: 30px;
  }
}

.MuiGrid-root .MuiTypography-root.link {
  color:rgb( 0, 30, 180)!important;
  cursor: pointer;
}

// 임시 (화상방 수업 관리 선택시 색깔 강조)
.fc-daygrid-bg-harness {
  border: 2px solid rgb(155, 236, 255);
}

.LATextInputButtonGroup {
  display: flex;
  align-items: center;
  border: 1px solid $formBorderColor;
  border-radius: $formBorderRadius;

  .inputWrap {
    flex: 1;
    margin: 0;

    input {
      height: 1.4375em;
      padding: 0.625rem;
      font-size: 0.75rem;
    }
  }

  button {
    width: 36px;
    height: 36px;
    padding: 0;

    & + button {
      margin-left: 14px;

      &:before{
        content: '';
        position: absolute;
        left: -8px;
        display: block;
        width: 1px;
        height: 20px;
        
        background-color: #eee;
      }
    }

    &:last-child {
      margin-right: 0.625rem;
    }

    &.btnText{
      min-width: initial;
      width: fit-content;
      padding: 0 10px;

      &:not(:disabled) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.LaMultiSelect {
  .MuiSelect-select {
    grid-template-columns: 100%;
    .text {
      padding-right: 1.5em;
      overflow:hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.mainSearchOptionBoard {
  footer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding-left: 15%;

    button.wide {
      min-width: 200px;
      padding: 10px 50px;
      font-size: 16px;
    }

    .btnAction + .btnAction{
      margin-left: 20px;
    }
  }
}

.dataPager {
  .cbEntriesWrap {
    flex: none;
    display: flex;
    align-items: center;
    width: 150px;

    .label {
      width: 100px;
    }
  }

  .entriesArea {
    .txtBar {
      display: inline-block;
      height: 10px;
      width: 1px;
      margin: 0 5px;
      background-color:#eee;
    }
  }
}


