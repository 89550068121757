.laModalPopup {
	.container{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.modalBody {
			overflow-y: scroll;
		}

		.modalFooter {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 30px;

			button.wide {
				padding: 10px 50px;
				font-size: 16px;
			}
		}
	}

	.laModalCardTitle {
		.btnOption + .btnOption{
			margin-left: 10px;
		}

		.MuiBox-root {
			padding-bottom: 16px;
			padding-right: 40px;

			h5 {
				padding-top: 0;
				line-height: 32px;
			}
		}
	}

	.btnModalClose {
		position: absolute;
		top: -20px;
		right: -20px;
		z-index: 1;
		background-color: #fff;
		box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 10%), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 6%);

		&:hover {
			background-color: #eee;
		}
	}
}